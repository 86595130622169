import React, { Component } from 'react';

export default class extends Component {

    render() {

        const { t } = this.props;

        return (
            <>
                <div className="container mb-5 mt-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="terms">
                            <h1>{t('Privacy Policy')}</h1>
                            <h2>PERSOONSGEGEVENS VAN DE KLANT</h2>
                            De klant geeft door zijn bestelling toestemming voor de verwerking van zijn persoonsgegevens voor één of meer specifieke
                            doeleinden van de prestatieplichtige.
                            De persoonsgegevens van de klant worden door de prestatieplichtige verwerkt conform de algemene verordening
                            gegevensbescherming (AVG) (of in het Engels: general data protection regulation (GDPR) en gepubliceerd op 04/05/2016) en
                            zijn enkel bestemd voor intern gebruik door de prestatieplichtige en door de ondernemingen die contractueel verbonden zijn
                            met deze laatste.
                            Die persoonsgegevens kunnen door de prestatieplichtige gebruikt worden voor verkoopsdoeleinden, zoals naverkoopdienst,
                            publiciteit, brochures, gebruik op sociale media van de prestatieplichtige, waarborg, veiligheid alsook klantenbeheer voor de
                            producten verdeeld door bovenvermelde gebruikers.
                            Conform de voornoemde AVG heeft de klant met betrekking tot zijn persoonsgegevens een recht op inzage, een recht op
                            rectificatie, een recht om vergeten te worden, een recht op beperking van de verwerking van zijn persoonsgegevens, een
                            recht op overdraagbaarheid van zijn gegevens en een recht van bezwaar.
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
};
                        
